import React from "react";
import { CheckIcon } from "@heroicons/react/outline";
import Layout from "../components/layout";
import Container from "../components/container";
import { useDispatch } from "react-redux";
import { toggleModal } from "../state/store";
import { classNames } from "../utils";
import { gtm } from "../tracking/gtm";

const pricing = {
  tiers: [
    {
      title: "Flex",
      price: 20,
      frequency: "/user/month",
      description: "A plan that scales to fit your business as it grows.",
      features: [
        "No limit on viewer seats",
        "Pay per developer seat",
        "Pay based on usage",
        "Data access controls",
        "24-hour support response time",
      ],
      cta: "Join Waitlist",
      mostPopular: true,
    },
    {
      title: "Enterprise",
      frequency: "/month",
      description: "Dedicated support and infrastructure for your company.",
      features: [
        "Unlimited team members",
        "Advanced access controls",
        "Dedicated support",
        "SSO",
        "Custom invoice",
      ],
      cta: "Contact Us",
      mostPopular: false,
    },
  ],
};

export default function Pricing() {
  const dispatch = useDispatch();
  return (
    <Layout title={"Pricing - Supersheets"}>
      <Container>
        <div className="max-w-7xl mx-auto py-16 px-4 bg-white sm:px-6 lg:px-8">
          <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl sm:leading-none sm:tracking-tight lg:text-4xl text-center">
            Pricing plans for teams of all sizes
          </h2>
          <div className="text-center mt-6 text-md text-gray-700 max-w-xl mx-auto">
            Supersheets offers flexible pricing that scales to fit your needs.
            It costs nothing to get started and you'll only pay for what you
            actually need.
          </div>

          {/* Tiers */}
          <div className="mt-16 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8">
            {pricing.tiers.map((tier) => (
              <div
                key={tier.title}
                className="relative p-8 bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col"
              >
                <div className="flex-1">
                  <h3 className="text-xl font-semibold text-gray-900">
                    {tier.title}
                  </h3>
                  {tier.mostPopular ? (
                    <p className="absolute top-0 py-1.5 px-4 bg-indigo-400 rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2">
                      Most popular
                    </p>
                  ) : null}
                  <p className="mt-6 text-gray-500">{tier.description}</p>

                  {/* Feature list */}
                  <ul role="list" className="mt-6 space-y-6">
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex">
                        <CheckIcon
                          className="flex-shrink-0 w-6 h-6 text-indigo-400"
                          aria-hidden="true"
                        />
                        <span className="ml-3 text-gray-500">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>

                <button
                  className={classNames(
                    tier.mostPopular
                      ? "bg-indigo-400 text-white hover:bg-indigo-500"
                      : "bg-indigo-40 text-indigo-700 hover:bg-indigo-100",
                    "mt-8 block w-full py-3 px-6 border border-indigo-100 rounded-md text-center font-medium"
                  )}
                  onClick={() => {
                    gtm({
                      event: "open_request_access",
                      action: "click",
                      properties: {
                        location: "pricing",
                      },
                    });
                    dispatch(toggleModal());
                  }}
                >
                  {tier.cta}
                </button>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </Layout>
  );
}
